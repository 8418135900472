<template>
    <div class="filters">
        <v-tabs :tabs="tabs"
                :tab="tab"
                class="tabs_filter"
                :class="{'tabs_dark': isDark()}"
                @onChange="changeTabHandler"
        ></v-tabs>

        <div class="filters__list">
            <transition name="slide-right" mode="out-in">
                <div v-if="tab === 'buy' || tab === 'rent'"
                     class="filters__item">
                    <v-filter
                        :listener="isListener()"
                        :page="page"
                        :sort="sort"
                        @onLoad="loadHandler"
                        @onLoading="loadingHandler"
                    ></v-filter>
                </div>
                <div v-else-if="tab === 'sell-form' || tab === 'rental-form'"
                     class="filters__item">
                    <v-form-filter :form="baseForm"
                                   onSubmit="submitHandler"
                    ></v-form-filter>
                </div>
            </transition>
        </div>
    </div>

    <template v-if="isListener()">
        <teleport v-if="isListener()" to="#catalog">
            <div class="catalog__services">
                <v-services
                    :sort="sort"
                    @onChange="changeSortHandler"
                ></v-services>
            </div>
            <div class="catalog__offers">
                <v-preloader v-if="isLoaded" class="catalog__preloader"></v-preloader>
                <div class="catalog__list">
                    <template v-if="offers">
                        <v-offer v-for="offer in offers"
                                 :name="offer.name"
                                 :location="offer.location"
                                 :link="offer.link"
                                 :type="offer.type"
                                 :price="offer.price"
                                 :period="offer.period"
                                 :price_per_meter="offer.price_per_meter"
                                 :rooms="offer.rooms"
                                 :area="offer.total_area"
                                 :floor="offer.floor"
                                 :floors="offer.floors_total"
                                 :additionally="offer.additionally"
                                 :thumbnail="offer.thumbnail"
                                 :images="offer.images"
                                 :key="offer.id"
                                 :style="{opacity: isLoaded ? .5: 1}"
                        ></v-offer>
                    </template>
                    <div v-if="!offers?.length" class="empty empty_center">
                        <div class="empty__title">Нет результатов</div>
                        <div class="empty__text">Попробуйте изменить значения фильтра</div>
                    </div>
                </div>
            </div>

            <div class="catalog__pagination">
                <transition name="opacity" v-if="pagination">
                    <template v-if="pagination">
                        <v-button v-if="pagination.num_pages > 1 && pagination.number < pagination.num_pages"
                                  @onClick="loadMoreHandler"
                                  class="catalog__more btn_full btn_primary center btn_outline">
                            <span v-if="isLoaded"><v-preloader></v-preloader></span>
                            <template v-else>Загрузить еше</template>
                        </v-button>
                    </template>
                </transition>

                <div class="catalog__services catalog__services_bottom" v-if="pagination">
                    <v-pagination v-if="pagination"
                                  :range="pagination.range"
                                  :number="pagination.number"
                                  :num_pages="pagination.num_pages"
                                  :ellipsis="pagination.ellipsis"
                                  class="products__pagination"
                                  @onChange="changePagesHandler"
                    ></v-pagination>
                </div>
            </div>
        </teleport>
    </template>
</template>

<script>
import {ref} from "vue";

import Services from "@components/catalog/Services";
import Offer from "@components/catalog/offers/Offer";

import Pagination from "@components/ui/Pagination";
import Button from "@components/ui/forms/Button";
import Preloader from "@components/ui/Preloader";
import Tabs from "@components/ui/Tabs";

import Filter from "@components/catalog/filters/Filter";
import BaseFormFilter from "@components/catalog/filters/form/BaseFormFilter";

import useFeedbackForm from "@use/form/base";
import {booleanFromString} from "@utils/string";

export default {
    name: "Catalog",
    components: {
        'v-filter': Filter,
        'v-form-filter': BaseFormFilter,
        'v-tabs': Tabs,

        "v-button": Button,
        "v-preloader": Preloader,

        'v-services': Services,
        'v-offer': Offer,
        'v-pagination': Pagination,
    },
    props: {
        type: {
            type: [Number, String, Boolean],
            default: 'buy',
        },
        dark: {
            type: [Boolean, String],
            default: true,
            validator: (value) => (typeof value === 'string' && value === 'false') || typeof value === 'boolean'
        },
        listener: {
            type: [Boolean, String],
            default: true,
            validator: (value) => (typeof value === 'string' && value === 'false') || typeof value === 'boolean'
        }
    },
    setup(props) {
        console.log(props.listener, props.type);

        const isListener = () => booleanFromString(props.listener);
        const isDark = () => booleanFromString(props.dark);

        let tabs = ref([{
            name: 'Купить',
            id: 'buy',
            url: '/catalog/buy/',
        }, {
            name: 'Снять',
            id: 'rent',
            url: '/catalog/rent/',
        }, {
            name: 'Продать',
            id: 'sell-form',
        }, {
            name: 'Сдать',
            id: 'rental-form',
        },
            // {
            //     name: 'Посуточная аренда',
            //     id: 'daily-rent',
            // }
        ]);

        let isLoaded = ref(true);
        let tab = ref(props.type);

        let offers = ref(null);
        let pagination = ref(null);
        let page = ref(null);
        let more = ref(false);
        let sort = ref(null);

        let baseForm = useFeedbackForm(true);

        function loadHandler(value) {
            isLoaded.value = false;
            if (more.value) {
                offers.value = [...offers?.value ?? [], ...value?.offers ?? []];
                more.value = false;
            } else {
                offers.value = value.offers;
            }

            pagination.value = value.pagination;
            sort.value = value.sort;
        }

        const loadingHandler = () => isLoaded.value = true;
        const changePagesHandler = (value) => {
            window.scrollTo(0, 0);
            page.value = value;
        };

        const changeSortHandler = (value) => sort.value = value;

        function loadMoreHandler() {
            page.value = pagination.value.number + 1;
            more.value = true;
        }

        function moreFilterHandler() {
            console.log('More filter');
        }

        function submitHandler() {
            console.log('Submit sell');
        }

        function changeTabHandler(value) {
            tab.value = value.id;
        }

        return {
            isLoaded,

            tabs,
            tab,

            offers,
            page,
            pagination,
            sort,

            baseForm,

            isListener,
            isDark,

            loadHandler,
            loadingHandler,

            loadMoreHandler,
            moreFilterHandler,

            changeTabHandler,
            changePagesHandler,
            changeSortHandler,

            submitHandler,
        }
    }
}
</script>

<style></style>
