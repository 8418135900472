export default function useFiltration(items, value, attr='name') {
    /**
     * Фильтация списка по ключу attr
     */
    return items.filter((item) => {
        return item[attr].toLowerCase().indexOf(value.toLowerCase()) >= 0;
        // return  (new RegExp(`${filtration.value}`,'i')).test(item.name) ;
        // return  item.name.toLowerCase().includes(filtration.value.toLowerCase());
    })
}
