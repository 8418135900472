<template>
    <v-option v-for="item in items"
              :key="item"
              :image="item.image"
              :name="item.name"
              :description="item.description"
              :class="[{'option_selected': item[attr]===value}, classOption]"
              @click="clickHandler(item)"
    >

        <template v-if="$slots.option" v-slot:item>
            {{item.id}} {{value}}
            <slot name="option" :item="item"></slot>
        </template>
    </v-option>
</template>

<script>
    import Option from "@components/ui/select/Option";

    /**
     * Пример переопределение Option template из родительского компонента
     *  <template v-slot:option="slotProps">
     *      <div>{{ slotProps.item.name }}</div>
     *  </template>
     */

    export default {
        name: "ItemsList",
        components: {
            'v-option': Option,
        },
        props: {
            items: {
                type: [Array],
                default: null
            },
            value: {
                type: [String, Number],
                default: null
            },
            auto: {
                type: Boolean,
                default: false
            },
            attr: {
                type: String,
                default: 'value'
            },
            classOption: {
                type: String,
                default: null
            },
        },
        setup(props, {emit}) {


            function clickHandler(item) {
                emit('onChange', item);
            }

            return {
                clickHandler,
            }
        }
    }
</script>

<style></style>
