<template>
    <div class="filter__form">
        <v-preloader v-if="pending" class="preloader"></v-preloader>
        <v-form
            :form="form"
            :submit-btn="true"
            submit-label="Оставить заявку"
            :compact="true"
            class="form_inline"
            @onSubmit="submitHandler">
        </v-form>
    </div>

    <v-modal v-model:show="send"
             width="400"
    >
        <template v-slot:default>
            <v-thanks class="thanks_order"
                      :title="thanks.title"
                      v-model:show="send"
                      :description="thanks.description"
            ></v-thanks>
        </template>
    </v-modal>
</template>

<script>
import Form from "@components/ui/forms/Form";
import Modal from "@components/ui/modal/Modal";
import Thanks from "@components/feedback/Thanks";
import {ref} from "vue";
import config from "@config/config";
import useFeedbackForm from "@use/form/base";
import useFeedback from "@use/data/feedback/ordering";
import useErrors from "@use/form/errors";
import Preloader from "@components/ui/Preloader";

export default {
    name: "BaseFormFilter",
    components: {
        // "v-button": Button,
        "v-form": Form,
        "v-modal": Modal,
        "v-thanks": Thanks,
        "v-preloader": Preloader,
    },
    props: {
        form: {
            type: Object,
            default: null,
        },
        url: {
            type: String,
            default: null,
        }
    },
    setup() {
        const show = ref(false);
        const pending = ref(false);
        const send = ref(false);
        const thanks = ref(config.thanks);

        const formFeedback = useFeedbackForm();
        async function submitHandler(values) {
            const {fetchData} = useFeedback({
                'name': values.fields.name?.value ?? '',
                'phone': values.fields.phone?.value ?? '',
            });

            pending.value = true;

            let data = await fetchData();
            if (data?.errors) {
                useErrors(data?.errors, values.fields, values);
            }

            if (data) {
                formFeedback.clear();
                pending.value = false;
                send.value = true;
            }
        }

        return {
            show,
            pending,
            send,
            thanks,
            submitHandler,
        }
    }
}
</script>

<style></style>
