<template>
    <div class="view">
        <div v-for="item in items"
             :key="item.id"
             class="view__item"
        >
            {{ item }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "View",
        props: {
            items: {
                type: Array,
                default: null
            }
        },
        setup() {
            return {}
        }
    }
</script>

<style></style>
