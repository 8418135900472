<template>
    <ul class="pagination">
        <li v-if="prev" class="pagination__item pagination__item_prev">
            <div class="pagination__link" @click="onClick(prev)"></div>
        </li>

        <li v-for="item in range"
            @click="onClick(item)"
            :key="item"
            :class="{
                'pagination__item_active': item===number,
                'pagination__item_ellipsis': item===ellipsis,
            }"
            class="pagination__item">
            <div class="pagination__link">{{item}}</div>
        </li>

        <li v-if="next" class="pagination__item pagination__item_next">
            <div class="pagination__link" @click="onClick(next)"></div>
        </li>
    </ul>
</template>

<script>
    import {computed} from "vue";

    export default {
        name: "Pagination",
        props: {
            range: {
                type: Array,
                default: null
            },
            number: {
                type: Number,
                default: null
            },
            num_pages: {
                type: Number,
                default: null
            },
            ellipsis: {
                type: String,
                default: '...'
            },
        },
        setup(props, {emit}) {
            let prev = computed(() => props.number > 1 ? props.number - 1 : null);
            let next = computed(() => props.number < props.num_pages ? props.number + 1 : null);

            function onClick(index) {
                if (index !== props.ellipsis && index !== props.number) emit('onChange', index);
            }

            return {
                onClick,
                prev,
                next
            }
        }
    }
</script>

<style scoped>

</style>
