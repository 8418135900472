<template>
    <li class="option"
         :class="{'option_selected': selected}"
    >
        <slot v-if="$slots.item" name="item" :item="item"></slot>
        <template v-else>
            <template v-if="name && !image && !description">{{name}}</template>
            <template v-else>
                <v-image v-if="image"
                         :src="image?.url"
                         :alt="name"
                         class="option__image"></v-image>

                <div class="option__info">
                    <div class="option__name">{{ name }}</div>
                    <div v-if="description" class="option__description">{{ description }}</div>
                </div>
            </template>
        </template>
    </li>
</template>

<script>
    import Image from "@components/ui/Image";

    export default {
        name: "Option",
        components: {
            'v-image': Image,
        },
        props: {
            image: {
                type: String,
                default: null
            },
            name: {
                type: String,
                default: null
            },
            description: {
                type: String,
                default: null
            },
            selected: {
                type: Boolean,
                default: false
            },
        },
        setup(props, {emit}) {
            function clickHandler(target) {
                emit('onClick', target)
            }

            return {
                clickHandler,
            }
        }
    }
</script>

<style lang="less">
    .option {
        clear: both;

        text-align: inherit;
        text-decoration: none;
        white-space: nowrap;
        border: 0;

        padding: .2rem .75rem;
        display: inline-flex;
        align-items: center;

        width: 100%;
        font-size: var(--p);
        min-height: 2rem;
        box-sizing: border-box;
        transition: background .2s ease;
        cursor: pointer;

        &:not(.option_selected) {
            &:hover {
                background: var(--ex-lt-gray);
            }
        }

        &__image,
        &__name,
        &__description {
            display: flex;
        }

        &__image {

        }

        &__info {

        }

        &__name {
            font-size: var(--h6);
            margin-bottom: .5rem;
        }

        &__description {
            font-size: var(--small);
            line-height: 1.125rem;
        }

        & div:last-child {
            margin-bottom: 0;
        }

        &_selected {
            background: var(--secondary);
            color: var(--white);
        }

        &_horizontal {

        }

        &_lg {
            padding: 1rem;
        }

        &_vertical {
            white-space: normal;
        }

        &_round {
            border-radius: var(--border-radius-sm);
        }

        &_center {
            justify-content: center;
        }
    }

</style>
