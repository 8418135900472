<template>
    <v-filter-panel
        @onFiltration="filtrationHandler"
    >
        <v-dropdown
            :placeholder="typesPlaceholder"
            class="dropdown_inline dropdown_left dropdown_full filter__item filter__item_type"
        >
            <template v-slot:content>
                <div class="feature">
                    <div class="feature__label">Тип недвижимости:</div>
                    <div class="feature__value">
                        <div class="btn-group">
                            <v-button v-for="item in types"
                                      :key="item"
                                      :name="item.name"
                                      class="btn_sm btn_default"
                                      :class=" {'btn_primary': item.active }"
                                      @onClick="changeTypeHandler(item)"
                            ></v-button>
                        </div>

                        <div class="filter__types" v-if="typeItems">
                            <v-checkbox v-for="item in typeItems"
                                        :label="item?.name ?? item.value"
                                        :name="item.value"
                                        v-model:checked="item.checked"
                                        :key="item"
                                        type="checkbox"
                                        class="filter__item filter__item_checkbox"
                            ></v-checkbox>
                        </div>
                    </div>
                </div>
            </template>
        </v-dropdown>

        <transition name="slide-right">
            <v-dropdown v-if="!commercial"
                        :placeholder="roomsPlaceholder"
                        class="dropdown_inline dropdown_min filter__item filter__item_room"
            >
                <template v-slot:content>
                    <div class="feature">
                        <div class="feature__label">Кол-во комнат:</div>
                        <div class="feature__value feature__value_column">
                            <div class="btn-group btn-group_line filter__rooms">
                                <v-button v-for="item in rooms.rooms"
                                          :key="item"
                                          :name="item.name"
                                          class="btn_sm btn_default"
                                          :class=" {'btn_primary': item.active }"
                                          @onClick="item.active = !item.active">
                                </v-button>
                            </div>

                            <div class="btn-group btn-group_line filter__room-options">
                                <v-checkbox
                                    class="filter__item filter__item_checkbox"
                                    label="Студия"
                                    name="studio"
                                    type="checkbox"
                                    v-model:checked="rooms.studio"
                                ></v-checkbox>
                            </div>
                        </div>
                    </div>
                </template>
            </v-dropdown>
        </transition>

        <v-dropdown
            :placeholder="areaPlaceholder"
            :width="240"
            class="dropdown_inline dropdown_min filter__item filter__item_area"
        >
            <template v-slot:content>
                <div class="feature">
                    <div class="feature__label">Площадь:</div>
                    <div class="feature__value">
                        <v-range
                            v-model:value="filters.total_area.value"
                            :max="filters.total_area.max"
                            :min="filters.total_area.min"
                            :step="10"
                        ></v-range>
                    </div>
                </div>
            </template>
        </v-dropdown>

        <v-dropdown
            :placeholder="pricePlaceholder"
            :width="240"
            class="dropdown_inline dropdown_min filter__item filter__item_price"
        >
            <template v-slot:content>
                <div class="feature">
                    <div class="feature__label">Стоимость:</div>
                    <div class="feature__value">
                        <v-range
                            v-model:value="filters.price.value"
                            :max="filters.price.max"
                            :min="filters.price.min"
                            :step="1000"
                        ></v-range>
                    </div>
                </div>
            </template>
        </v-dropdown>

        <v-dropdown
            :placeholder="locationPlaceholder"
            class="dropdown_inline dropdown_full filter__item filter__item_location"
        >
            <template v-slot:content>
                <div class="features">
                    <div class="feature filter__locality">
                        <div class="feature__label">Город:</div>
                        <div class="feature__value">
                            <v-checkbox v-for="item in filters.locality_name.items"
                                        :label="item.value"
                                        :name="item.value"
                                        v-model:checked="item.checked"
                                        :key="item"
                                        type="checkbox"
                                        class="filter__item filter__item_checkbox"
                                        @onChange="checkedLocalityHandler(item)"
                            ></v-checkbox>
                        </div>
                    </div>


                    <!--                    <div class="feature filter__sub-locality">-->
                    <!--                        <div class="feature__label">Район:</div>-->
                    <!--                        <div class="feature__value">-->

                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>
            </template>
        </v-dropdown>

        <template v-slot:modal>
            <div class="row filter__more">
                <div class="features">
                    <div class="feature">
                        <div class="feature__label">Удобства:</div>
                        <div class="feature__value">
                            <div class="">
                                <v-checkbox v-if="filters.washing_machine"
                                    label="Стиральная машина"
                                    name="washing_machine"
                                    v-model:checked="filters.washing_machine.value"
                                ></v-checkbox>

                                <v-checkbox v-if="filters.refrigerator"
                                    label="Холодильник"
                                    name="refrigerator"
                                    v-model:checked="filters.refrigerator.value"
                                ></v-checkbox>

                                <v-checkbox v-if="filters.internet"
                                    label="Интернет"
                                    name="internet"
                                    v-model:checked="filters.internet.value"
                                ></v-checkbox>

                                <v-checkbox v-if="filters.television"
                                    label="Телевизор"
                                    name="television"
                                    v-model:checked="filters.television.value"
                                ></v-checkbox>

                                <v-checkbox v-if="filters.microwave"
                                    label="Микроволновка"
                                    name="microwave"
                                    v-model:checked="filters.microwave.value"
                                ></v-checkbox>

                                <v-checkbox v-if="filters.room_furniture"
                                    label="Мебель"
                                    name="room_furniture"
                                    v-model:checked="filters.room_furniture.value"
                                ></v-checkbox>

<!--                                <v-checkbox-->
<!--                                    label="Телефон"-->
<!--                                    name="telephone"-->
<!--                                    v-model:checked="filters.phone"-->
<!--                                ></v-checkbox>-->
                            </div>

                        </div>
                    </div>
                </div>


                <!--                <div class="floor">-->
                <!--                    Не первый, не последний, только последний-->
                <!--                </div>-->
            </div>
        </template>
    </v-filter-panel>

    <div v-if="results" class="filter__result">Найдено объектов: <strong>{{ results }}</strong></div>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";

import FilterPanel from "@components/catalog/filters/FilterPanel";
import Dropdown from "@components/ui/Dropdown";
import Button from "@components/ui/forms/Button";
import Range from "@components/ui/Range";
import Checkbox from "@components/ui/forms/Switch";

import useCatalog from "@use/data/catalog/catalog";
import useFiltersBase from "@use/data/catalog/filtersBase";

import {capitalize} from "@utils/string";

export default {
    name: "Filter",
    components: {
        'v-filter-panel': FilterPanel,
        'v-dropdown': Dropdown,
        'v-button': Button,
        'v-range': Range,
        'v-checkbox': Checkbox,
    },
    props: {
        listener: {
            type: [Boolean, String],
            default: false,
            validator: (value) => (typeof value === 'string' && value === 'false') || typeof value === 'boolean'
        },
        type: {
            type: Number
        },
        page: {
            type: Number
        },
        sort: {
            type: String
        },
    },
    setup(props, {emit}) {
        const filters = ref(null);
        const offers = ref(null);
        const commercial = ref(null);
        const params = ref(null);
        const results = ref(null);

        const filterMore = ref({
            studio: false,
            apartments: false,

            washing_machine: false,
            refrigerator: false,
            internet: false,
            television: false,
            microwave: false,
            room_furniture: false,
            kitchen_furniture: false,
            lift: false,
            toilet: false,
            phone: false,
            is_elite: false,
            access_control_system: false,

            with_children: false,
            with_pets: false,

            rent_pledge: false,
            utilities_included: false,

            photos: false,
        });

        onMounted(async () => {
            if (props.listener) {
                if (window.location.search !== '') params.value = new URLSearchParams(window.location.search);

                let data = await getCatalogData(params.value);
                filters.value = Object.assign(filterMore?.value ?? {}, data?.filters ?? {});
                offers.value = data?.offers ?? {};

                update(data);
            }else{
                let data = await getFilterData();
                filters.value = Object.assign(filterMore?.value ?? {}, data ?? {});

                // if (data) {
                //     typeItems.value = data?.type ? filters.value.category.items : [];
                // }
            }
        });

        window.onpopstate = function (event) {
            console.log("location: " + document.location + ", state: " + JSON.stringify(event.state));
        };

        const getCatalogData = async (params) => {
            let {fetchData} = useCatalog(params);
            emit('onLoading');
            return await fetchData();
        }

        const getFilterData = async (params) => {
            let {fetchData} = useFiltersBase(params);
            return await fetchData();
        }

        const update = (data, params) => {
            /**
             * Обновление данных в каталоге
             */
            if (data) {
                typeItems.value = data?.type ? filters.value.category.items : [];

                if (params) {
                    window.history.pushState(
                        {params: params?.toString()},
                        '',
                        `?${params?.toString()}`
                    );
                }

                results.value = data?.results ?? null;
                emit('onLoad', data);
            }
        }

        const types = ref([{
                name: 'Жилая',
                commercial: false,
                active: true,
            }, {
                name: 'Коммерческая',
                commercial: true,
                active: false,
            }]),
            typeItems = ref([]);

        const getCheckedListId = (items) => {
            let checked = []
            items.map((item) => {
                if (item.checked) checked.push(item.id)
            })

            return checked
        }

        const getCheckedListName = (items) => {
            let checked = []
            items.map((item) => {
                if (item.checked) checked.push(capitalize(item.value))
            })

            return checked
        }

        const typesPlaceholder = computed(() => {
            let checked = [];
            if (filters.value) {
                if (commercial.value) {
                    checked = getCheckedListName(filters.value.commercial_type.items);
                } else {
                    checked = getCheckedListName(filters.value.category.items);
                }
            }

            return checked.length ? checked.join(', ') : commercial.value ? `Помещение` : `Квартиру или комнату`
        })

        const rooms = ref({
                rooms: [
                    {
                        name: '1',
                        value: 1,
                        active: false,
                    }, {
                        name: '2',
                        value: 2,
                        active: false,
                    }, {
                        name: '3',
                        value: 3,
                        active: false,
                    }, {
                        name: '4',
                        value: 4,
                        active: false,
                    }, {
                        name: '5',
                        value: 5,
                        active: false,
                    }, {
                        name: '6+',
                        value: 6,
                        active: false,
                    },
                ],
                studio: false
            }),
            roomsPlaceholder = computed(() => {
                let result = '';
                let actives = rooms.value.rooms.filter((item) => item.active)
                let studio = rooms.value.studio;

                if (actives.length) {
                    if (actives.length > 1) {
                        let groups = actives.reduce((arr, val, i, a) => {
                            if (!i || val.value !== a[i - 1].value + 1) arr.push([]);
                            arr[arr.length - 1].push(val.name);
                            return arr;
                        }, [])

                        let list = []
                        for (const group of groups) {
                            if (group.length > 2) {
                                list.push(`<b>${group[0]} - ${group[group.length - 1]}</b>`)
                            } else if (group.length === 2) {
                                list.push(`<b>${group[0]}</b>, <b>${group[1]}</b>`)
                            } else {
                                list.push(`<b>${group[0]}</b>`)
                            }
                        }

                        result = `${list.join(', ')} комн.`
                    } else {
                        if (actives[0].name === '6+' || studio) {
                            result = `<b>${actives[0].name}</b> комн.`
                        } else {
                            result = `<b>${actives[0].name}</b> - комнатную`
                        }
                    }
                }

                if (studio) result += actives.length ? ', <b>студия</b>' : 'Студия'
                return result.length ? result : 'Комнат'
            })

        const notDefault = (value) => value.min !== value.value[0] || value.max !== value.value[1]
        const rangePlaceholderValue = (value, postfix) => {
            if (value) {
                if (notDefault(value)) {
                    return ` от <b>${value.value[0]}</b> до <b>${value.value[1]}</b> ${postfix}`
                }
            }
            return null
        }

        const areaPlaceholder = computed(() => {
            let result = rangePlaceholderValue(filters.value?.total_area, 'м²')
            return result ? result : `Площадь`
        })

        const pricePlaceholder = computed(() => {
            let result = rangePlaceholderValue(filters.value?.price, '₽')
            return result ? result : `Стоимость`
        })

        let locality = ref({
            locality: null,
            sub_locality: null,
            metro: null,
        })

        const locationPlaceholder = computed(() => {
            let checked = [];
            if (filters.value) {
                checked = getCheckedListName(filters.value.locality_name.items);
            }

            return checked.length ? checked.join(', ') : 'Город, район'
        })

        function clickTypeHandler(value) {
            console.log(value);
        }

        const unchecked = (target, key = 'checked', value = false) => {
            target.map(item => item[key] = value);
        }

        function changeTypeHandler(value) {
            if (commercial.value !== value.commercial) {
                unchecked(types.value, 'active');
                value.active = true;

                commercial.value = value.commercial;

                unchecked(filters.value.commercial_type.items);
                unchecked(filters.value.category.items);

                typeItems.value = commercial.value ?
                    filters.value.commercial_type.items :
                    filters.value.category.items;
            }
        }


        // function setURL(payload) {
        //     let {data, params} = payload;
        //
        //     if (params) {
        //         if (pagination?.num_pages === null) {
        //             params.delete('page');
        //         }
        //     }
        //
        //     let {offers} = data;
        //     window.history.pushState(
        //         {
        //             offers,
        //             params: params?.toString()
        //         },
        //         '',
        //         `?${params?.toString()}`
        //     );
        // }

        function checkedLocalityHandler(value) {
            console.log(value);
        }

        function localityHandler(value) {
            console.log(value);
        }

        async function filtrationHandler() {
            /**
             * Клик по кнопке найти, смене страниц или сортировки
             * @type {{}}
             */

            let params = {};

            Object.keys(filters.value ?? {}).map((key) => {
                const item = filters?.value[key];
                if (item) {
                    if (item?.type) {
                        if (item['type'] === 'range') {
                            if (notDefault(item)) {
                                params[key] = item.value;
                            }
                        } else if (item['type'] === 'list') {
                            let ids = getCheckedListId(item?.items);
                            if (ids.length) params[key] = ids;
                        } else if (item['type'] === 'checkbox') {
                            if(item?.value) params[key] = item?.value;
                        }
                    }
                }
            })

            if (props.page) params['page'] = props.page;
            if (props.sort) params['sort'] = props.sort;

            let result = new URLSearchParams(params);

            if (!props.listener) {
                window.location.href = `/catalog/?${result?.toString() ?? ''}`;
            } else {
                let data = await getCatalogData(result);
                update(data, result);
            }
        }

        watch(() => props.page, () => filtrationHandler())
        watch(() => props.sort, () => filtrationHandler())

        return {
            filters,
            commercial,
            results,

            types,
            typeItems,

            locality,
            rooms,

            roomsPlaceholder,
            typesPlaceholder,
            pricePlaceholder,
            areaPlaceholder,
            locationPlaceholder,

            localityHandler,
            checkedLocalityHandler,

            changeTypeHandler,
            clickTypeHandler,

            filtrationHandler,
        }
    }
}
</script>

<style></style>
