<template>
    <div class="select"
         :class="{'select_open':show}"
         v-click-outside="blurHandler"
    >
        <div v-if="label" class="select__label">{{label}}</div>
        <div class="select__wrapper">
            <v-button class="btn_sm select__trigger"
                      @onClick="openHandler"
            >
                <slot v-if="$slots.icon" name="icon"></slot>
                <slot v-if="$slots.trigger" name="trigger" :value=active></slot>
                <template v-else>
                    <template v-if="active">{{active}}</template>
                    <template v-else>{{placeholder}}</template>
                </template>
            </v-button>

            <transition name="opacity">
                <div v-if="$slots.content" class="select__content" :style="style">
                    <slot name="content" :item="item"></slot>
                </div>
            </transition>

            <transition name="opacity">
                <ul v-if="show && options" class="select__list" :style="style">
                    <li v-if="filter" class="select__filter">
                        <v-input type="text"
                                 placeholder="Фильтровать"
                                 @onInput="itemsFiltrationHandler"
                        >
                        </v-input>
                    </li>

                    <v-options :items="options"
                               :value="value"
                               :attr="attr"
                               :classOption="classOption"
                               @onChange="clickHandler"
                    ></v-options>
                </ul>
            </transition>
        </div>
    </div>
</template>

<script>
    import {computed, ref} from "vue";

    import useFiltration from "@use/filtration";

    import Button from "@components/ui/forms/Button";
    import Input from "@components/ui/forms/Input";
    import Options from "@components/ui/select/Options";

    /**
     * Добавить дерективу для работы
     * .directive("click-outside", clickOutside)
     */
    /**
     * Пример переопределение Option template из родительского компонента
     *  <template v-slot:option="slotProps">
     *      <div>{{ slotProps.item.name }}</div>
     *  </template>
     */

    export default {
        name: "Select",
        components: {
            "v-button": Button,
            'v-input': Input,
            'v-options': Options,
        },
        props: {
            id: {
                type: Number,
                default: null
            },
            value: {
                type: [String, Number],
                default: null
            },
            attr: {
                type: String,
                default: 'value'
            },
            classOption: {
                type: String,
                default: null
            },
            label: {
                type: String,
                default: null
            },
            placeholder: {
                type: String,
                default: '-'
            },
            items: {
                type: [Array],
                default: null
            },
            height: {
                type: Number,
                default: null
            },
            width: {
                type: Number,
                default: null
            },
            filter: {
                type: Boolean,
                default: false
            },
            multiple: {
                type: Boolean,
                default: false
            },
            tags: {
                type: Boolean,
                default: false
            },
        },
        setup(props, {emit}) {
            let show = ref(false);
            let filtration = ref('');

            let active = computed(() => {
                if (props.items) {
                    for (const item of props.items) {
                        if (props?.value === item.value) {
                            return item?.name
                        }
                    }
                }

                return props.placeholder
            });

            let options = computed(() => {
                return useFiltration(props.items, filtration.value);
            })

            let style = computed(() => {
                return `width: ${props.width}px; height: ${props.height}px`
            });

            function clickHandler(target) {
                show.value = false;
                emit('onChange', target);
            }

            function openHandler() {
                show.value = !show.value;
                emit('onOpen');
            }

            function itemsFiltrationHandler(value) {
                filtration.value = value;
            }

            function blurHandler() {
                show.value = false;
                filtration.value = '';
                emit('onBlur');
            }

            return {
                options,

                show,
                active,
                style,

                openHandler,
                blurHandler,
                clickHandler,
                itemsFiltrationHandler,
            }
        }
    }
</script>

<style></style>
