<template>
    <div class="range">
        <div class="range__container">
            <div class="range__bar" ref="bar">
                <div class="range__range"
                     :style=range
                ></div>

                <div v-for="(item, index) in values"
                     :key=item
                     :style="{'left': `${position[index]-size/2}px`}"
                     :ref="el => { pointer[index] = el }"
                     class="range__pointer"
                ></div>

                <div class="range__area"
                     ref="area"
                ></div>
            </div>
        </div>

        <div v-if="inputs" class="range__inputs">
            <v-input :value="values[0]"
                     type="text"
                     class="range__input"
                     @onChange="onChangeLow"
            >
                <template v-slot:prepend>от</template>
            </v-input>

            <v-input :value="values[1]"
                     type="text"
                     class="range__input"
                     @onChange="onChangeHigh"
            >
                <template v-slot:prepend>до</template>
            </v-input>
        </div>
    </div>
</template>

<script>
    import {computed, onMounted, reactive, ref} from "vue";

    import useDrag from "@use/drag";
    import Input from "@components/ui/forms/Input";
    import {getNumber} from "@utils/string";

    export default {
        name: "Range",
        components: {
            'v-input': Input,
        },
        props: {
            value: {
                type: [Number, Array],
                default: null
            },
            min: {
                type: Number,
                default: null,
            },
            max: {
                type: Number,
                default: null,
            },
            tooltips: {
                type: Boolean,
                required: false,
                default: true,
            },
            step: {
                type: Number,
                required: false,
                default: 10
            },
            size: {
                type: Number,
                default: 16
            },
            inputs: {
                type: Boolean,
                default: true
            },
        },
        setup(props, {emit}) {
            let pointer = reactive([]);
            let area = ref(null);
            let bar = ref(null);
            let width = ref(0);

            let values = ref(props.value);
            let low = ref(values.value[0]);
            let high = ref(values.value[1]);

            let interval = computed(() => {
                return props.max - props.min;
            })

            let range = computed(() => {
                let [low, high] = position.value;
                let lowPosition = low;
                let width = high - low;

                return {
                    'left': `${lowPosition}px`,
                    'width': `${width}px`,
                }
            })

            let position = computed(() => {
                let [low, high] = getValue();
                let lowPosition = valueToPx(low);
                let highPosition = valueToPx(high);

                return [
                    lowPosition,
                    highPosition
                ]
            })

            let {dragInit, callbacks, mouse_offset_x, start_x} = useDrag(area, emit, 100, false, true);

            onMounted(() => {
                dragInit();
                callbacks.onMouseMove = onMouseMove;
                callbacks.onMouseUp = onMouseUp;
                callbacks.onMouseUpOutside = onMouseUpOutside;

                width.value = bar.value.offsetWidth
                window.addEventListener('resize', onResize);
            })

            function onMouseUpOutside() {
                let offset = getOffset();
                changeValue(checkValues(...checkPosition(offset)))
            }

            function onResize() {
                width.value = bar.value?.offsetWidth
            }

            function getOffset() {
                let x = start_x.value - area.value?.getBoundingClientRect().left;
                return x + mouse_offset_x.value - props.size;
            }

            function onMouseUp() {
                let offset = getOffset();
                if (xPosition(offset)) {
                    changeValue(checkPosition(offset))
                }
            }

            function onMouseMove() {
                let offset = getOffset();
                if (xPosition(offset)) {
                    values.value = checkPosition(offset);
                }
            }

            function xPosition(value) {
                return value <= width.value && value >= 0;
            }

            function checkPosition(value) {
                let [low, high] = position.value;

                if (value > high) {
                    high = value
                } else if (value < low) {
                    low = value
                } else {
                    let medium = high - (high - low) / 2;
                    if (value < medium) {
                        low = value
                    } else {
                        high = value
                    }
                }

                return [positionToValue(low), positionToValue(high)]
            }


            function checkValue(value) {
                return minmax(value, props.min, props.max)
            }

            function minmax(value, min, max) {
                return Math.min(Math.max(value, min), max)
            }

            function checkValues(low, high) {
                return [checkValue(low), checkValue(high)]
            }

            function valueToPx(value) {
                return PrcToPx(valuesToPrc(checkValue(value)))
            }

            function getPosition(value) {
                return {'left': `${value}px`}
            }

            function valuesToPrc(value) {
                return ((value - props.min) * 100 / interval.value);
            }

            // eslint-disable-next-line no-unused-vars
            function valuesToPx(value) {
                return (width.value / props.max) * value;
            }

            function PrcToPx(value) {
                return (width.value / 100) * value;
            }

            function positionToValue(position) {
                let value = ((position / width.value) * interval.value) + props.min;
                return Math.round(value / props.step) * props.step;
            }

            function is_ValueDefault(value) {
                return value[0] === props.min && value[1] === props.max
            }

            function getValue() {
                return values.value
            }

            function onChangeLow(value) {
                changeValue([checkValue(getNumber(value)), values.value[1]])
            }

            function onChangeHigh(value) {
                changeValue([values.value[0], checkValue(getNumber(value))])
            }

            function changeValue(value) {
                values.value = value
                emit('update:value', values.value);

                if (is_ValueDefault(values.value)) {
                    emit('onDefaultValue', values.value);
                } else {
                    emit('onChange', values.value);
                }
            }

            return {
                onChangeLow,
                onChangeHigh,
                getPosition,
                values,
                low,
                high,
                pointer,
                area,
                bar,
                range,
                position
            }
        }
    }
</script>


<style lang="less">
    .range {
        --size: 1rem;
        user-select: none;

        &__container {

        }

        &__bar {
            position: relative;
            display: flex;
            align-items: center;

            width: 100%;
            border-radius: calc(var(--size) / 4);
            height: calc(var(--size) / 2);
            background: var(--gray-light);
        }

        &__pointer {
            pointer-events: none;
            width: var(--size);
            height: var(--size);
            background: var(--white);
            box-shadow: var(--shadow-sm);
            border-radius: 50%;
            position: absolute;
            z-index: 99;

            &:hover {
                background: var(--secondary);
            }
        }

        &__range {
            pointer-events: none;
            height: calc(var(--size) / 2);
            background: var(--primary);
            z-index: 0;
            position: absolute;
        }

        &__area {
            width: calc(100% + 16px);
            height: calc(var(--size) * 2);
            position: absolute;
            left: -8px;
            z-index: 2;
        }

        &__inputs {
            margin-top: 1rem;
            display: flex;

            .input {
                &:last-child {
                    margin-left: 10px;
                }
            }
        }

        &__input {
            width: 100%;
        }
    }
</style>
