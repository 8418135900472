<template>
    <div class="dropdown"
         :class="{'dropdown_open': show}"
         v-click-outside="blurHandler"
    >
        <div class="dropdown__wrapper">
            <v-button class="dropdown__trigger"
                      @onClick="openHandler"
            >
                <slot v-if="$slots.icon" name="icon"></slot>
                <slot v-if="$slots.trigger" name="trigger" :value=value></slot>
                <template v-else>
                    <template v-if="value">{{ value }}</template>
                    <span v-else v-html="placeholder"></span>
                </template>
            </v-button>

            <transition name="opacity">
                <div v-if="$slots.content && show" class="dropdown__content" :style="style">
                    <slot name="content"></slot>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Button from "@components/ui/forms/Button";
import {computed, ref} from "vue";

export default {
    name: "Dropdown",
    components: {
        "v-button": Button,
    },
    props: {
        id: {
            type: Number,
            default: null
        },
        attr: {
            type: String,
            default: 'value'
        },
        placeholder: {
            type: String,
            default: '-'
        },
        height: {
            type: Number,
            default: null
        },
        width: {
            type: Number,
            default: null
        },
        value: {
            type: Boolean,
            default: false
        },
    },
    setup(props, {emit}) {
        let show = ref(false);

        let style = computed(() => {
            return `width: ${props.width}px; height: ${props.height}px`
        });

        function clickHandler(target) {
            show.value = false;
            emit('onChange', target);
        }

        function openHandler() {
            show.value = !show.value;
            emit('onOpen');
        }

        function blurHandler() {
            show.value = false;
            emit('onBlur');
        }

        return {
            show,
            style,

            openHandler,
            blurHandler,
            clickHandler,
        }
    }
}
</script>

<style></style>
