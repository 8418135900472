import {createApp} from "vue";
import {store} from "@/store";

import Catalog from "@components/catalog/filters/Catalog";
import {clickOutside} from "@/directive/clickOutSide";

window.addEventListener('load', async () => {
    let $filter = document.querySelector('#filter');

    if ($filter) {
        let datasetSearch = $filter.dataset ?? {};
        createApp(Catalog, {...datasetSearch})
            .use(store)
            .directive("click-outside", clickOutside)
            .mount($filter);
    }
})
