<template>
    <div class="filter">
        <main v-if="$slots.default" class="filter__container">
            <div class="filter__content">
                <slot name="default"></slot>
            </div>

            <v-button @onClick="showMoreFilterHandler"
                      class="filter__more">
                <div class="filter__icon" title="Ещё фильтры"></div>
            </v-button>

<!--            <v-button @onClick="clearFilterHandler"-->
<!--                      class="btn_center btn_sm btn_three filter__clear">Очистить фильтр-->
<!--            </v-button>-->
        </main>

        <v-button @onClick="filterHandler"
                  class="btn_center btn_primary filter__submit">Найти
        </v-button>
    </div>

    <v-modal v-model:show="show"
             :divide="true"
             :width="640">

        <template v-slot:header>
            <h3>Еще фильтры</h3>
        </template>

        <template v-slot:default>
            <slot name="modal"></slot>
        </template>

        <template v-slot:footer>
            <v-button class="btn_primary btn_center"
                      @onClick="filterHandler">Подобрать
            </v-button>
        </template>
    </v-modal>
</template>


<script>
/**
 *  Шаблон панели для фильтров
 */
import Button from "@components/ui/forms/Button";
import Modal from "@components/ui/modal/Modal";
import {ref} from "vue";

export default {
    name: "FilterPanel",
    components: {
        "v-button": Button,
        "v-modal": Modal,
    },
    setup(_, {emit}) {
        let show = ref(false);
        const showMoreFilterHandler = () => show.value = true;
        function filterHandler() {
            show.value = false;
            emit('onFiltration');
        }

        // function clearFilterHandler() {
        //     show.value = false;
        //     emit('onClearFilter');
        // }

        return {
            show,
            filterHandler,
            // clearFilterHandler,
            showMoreFilterHandler,
        }
    }
}
</script>

<style></style>
