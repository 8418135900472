<template>
    <div class="input">
        <div v-if="$slots.prepend" class="input__prepend">
            <slot name="prepend"></slot>
        </div>

        <input :id="name"
               :type="type"
               :value="value"
               :placeholder="placeholder"
               @input="onInput"
               @change="onChange"
               :autocomplete="autocomplete"
        >

        <div v-if="$slots.append" class="input__append">
            <slot name="append"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Input",
        props: {
            id: {
                type: String,
                default: null,
            },
            name: {
                type: String,
                default: null,
            },
            placeholder: {
                type: String,
                default: null,
            },
            type: {
                type: String,
                default: 'text',
            },
            value: {
                type: [String, Number],
                default: null,
            },
            autocomplete: {
                type: String,
                default: 'off',
            }
        }, setup(props, {emit}) {
            function onChange(e) {
                emit('onChange', e.target.value)
            }

            function onInput(e) {
                emit('onInput', e.target.value)
            }

            return {
                onInput,
                onChange
            }
        }
    }
</script>

<style lang="less">
    .input {
        border: var(--border);
        border-radius: var(--border-radius-sm);
        height: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
            outline: none;
            border: none;
            width: 100%;
            padding: 0 .2rem;
        }

        &__prepend,
        &__appand {
            padding: 0 .25rem;
            box-sizing: border-box;
            font-size: var(--label-size);
            color: var(--label-color);
        }
    }
</style>
