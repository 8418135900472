<template>
    <div :class="type">
        <input :id="index"
               :name="name"
               :checked="checked"
               type="checkbox"
               @change="onChange($event, value)"
        >

        <label :for="index">
            <span
                v-if="$slots.default || label">
                <slot name="default">{{ label }}</slot>
            </span>
        </label>
    </div>
</template>

<script>

import {computed} from "vue";

export default {
    props: {
        type: {
            type: String,
            default: 'switch',
            validator(value) {
                return ['switch', 'checkbox'].includes(value)
            }
        },
        id: {
            type: [String, Number],
            default: null
        },
        name: {
            type: String,
            default: null,
            required: true
        },
        label: {
            type: String,
            default: null
        },
        value: {
            type: [String, Number],
            default: '',
        },
        checked: {
            type: [Boolean, Number],
            default: false,
        },
    },
    setup(props, {emit}) {
        let index = computed(() => props.id ? props.id : props.name);

        function onChange(e, value) {
            emit('update:checked', e.target.checked);
            emit('onChange', value ? {
                checked: e.target.checked,
                value
            } : e.target.checked);
        }

        return {
            onChange,
            status,
            index
        }
    }
}
</script>

<style lang="less">
.switch {
    @size: 16px;
    @color: var(--gray);
    @radius: @size/2;
    @x: 2;
    margin: .5rem 0;
    min-height: @size;

    input[type=checkbox] {
        opacity: 0;
        position: absolute;
        width: @size;
        height: @size;
    }

    label {
        display: inline-block;
        cursor: pointer;
        max-width: 320px;
        height: @size;
        position: relative;
        padding-left: @size*@x+10;
        line-height: @size;
        box-sizing: border-box;

        &:before,
        &:after {
            content: '';
            position: absolute;
        }

        &:before {
            @size-point: @size - 4;
            z-index: 2;
            left: 2px;
            top: 2px;
            transition: all .2s ease;
            width: @size-point;
            height: @size-point;
            background: var(--white);
            border-radius: @size-point/2;
        }

        &:after {
            border-radius: @radius;
            transition: all .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            top: 0;
            left: 0;
            display: inline-block;
            background: @color;
            width: @size*@x;
            height: @size;
        }
    }

    &:active {
        input[type=checkbox] {
            &:not([disabled]) {
                &:checked {
                    & + label {
                        &:before {
                            left: (@size*@x)-(@size+2);
                            width: (@size);
                        }
                    }
                }

                & + label {
                    &:before {
                        width: (@size);
                    }
                }
            }
        }
    }

    input[type=checkbox] {
        &:checked {
            & + label {
                &:before {
                    left: (@size*@x)-(@size - 2);
                }

                &:after {
                    background: var(--primary);
                }
            }
        }

        &:disabled {
            & + label {
                color: var(--gray);

                &:after {
                    background: var(--gray-light);
                }
            }

            &:checked {
                & + label {
                    color: var(--gray);

                    &:after {
                        background: var(--gray-light);
                    }
                }
            }
        }
    }

    &_right {
        label {
            padding-left: 0;
            padding-right: @size*@x+10;

            &:before {
                @size-point: @size - 4;
                left: auto;
                right: 2px;
            }

            &:after {
                left: auto;
                right: 0;
            }
        }

        &:active {
            input[type=checkbox] {
                &:not([disabled]) {
                    &:checked {
                        & + label {
                            &:before {
                                left: auto;
                                right: (@size*@x)-(@size+2);
                            }
                        }
                    }
                }
            }
        }

        input[type=checkbox] {
            &:checked {
                & + label {
                    &:before {
                        left: auto;
                        right: (@size*@x)-(@size - 2);
                    }
                }
            }
        }
    }

    &_full {
        label {
            width: 100%;
        }
    }
}
</style>

