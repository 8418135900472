<template>
    <nav class="tabs">
        <div class="tabs__wrapper">
            <ul class="tabs__list">
                <li v-for="item in tabs"
                    :key="item.id"
                    class="tabs__item"
                    :class="{'tabs__item_active': item.id===tab}"
                    @click="clickHandler(item)"
                >
                    {{ item.name }}
                </li>
            </ul>

            <div v-if="line" class="tabs__line"></div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "TabBar",
    props: {
        tabs: {
            type: Array,
            default: null
        },
        tab: {
            type: [String],
            default: null
        },
        isScrollable: {
            type: Boolean,
            default: false
        },
        line: {
            type: Boolean,
            default: false
        },
    },
    setup(props, {emit}) {
        const clickHandler = (value) => emit('onChange', value);

        return {
          clickHandler
        }
    }
}
</script>

<style lang="less"></style>
