<template>
    <v-select
        :items="sortData"
        :value="sort"
        label="Сортировка"
        @onChange="onChangeSort"
    ></v-select>

    <!--    <v-select-->
    <!--        :items="quantityData"-->
    <!--        :value="quantity"-->
    <!--        label="Показывать по:"-->
    <!--        class-option="option_center"-->
    <!--        @onChange="onChangeAmount"-->
    <!--    ></v-select>-->

    <v-view></v-view>
</template>

<script>
import View from "@components/catalog/View";
import Select from "@components/ui/select/Select";

export default {
    name: "Services",
    components: {
        'v-view': View,
        'v-select': Select,
    },
    props: {
        sort: String
    },
    setup(_, {emit}) {
        let sortData = [
            {name: 'Цена по возрастанию', value: 'price',},
            {name: 'Цена по убыванию', value: '-price',},
            {name: 'Большая плошадь', value: '-total_area',},
            {name: 'Маленькая плошадь', value: 'total_area',},
            {name: 'Дата обновления', value: 'updated_at',},
        ]
        const onChangeSort = (value) => emit('onChange', value.value);

        return {
            sortData,
            onChangeSort,
        }
    }
}
</script>

<style></style>
